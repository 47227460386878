/*
 * PACKAGES
 */
export const _initialState = {
    isChatBotOpen: false,
    turnOnCompare: false,
    isInsightsOpen: true,
    sidebarCollapse: false,
    isCsv: "console",
    isDemoTrue: true,
    isTokenChange : false,
    gmbSheetCreatedCount: 0
   
};
  
  /*
   * OBJECTS
   */
  const _Local = (__prevState = _initialState, __action) => {
    // console.log(__action, _initialState, __prevState)
    /*
     * Switch case for handling actions on
     * local store.
     */
    switch (__action.type) {
      case "LOCAL_UPDATE":
        // Return combined state.
        return {
          ...__prevState,
          ...__action.Local,
        };
      case "LOCAL_CLEAR":
        // Return combined state.
        return {
          ..._initialState,
        };
      default:
        // Return old state.
        return __prevState;
    }
  }
  
  /*
   * EXPORTS
   */
  export default _Local;
  