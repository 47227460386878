import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
	colors: {
		mediotix: {
          100: "#2c3775",
		  200: "#5460a1"
		},
		brand: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#11047A'
		},
		
		brandScheme: {
			100: '#E9E3FF',
			200: '#7551FF',
			300: '#7551FF',
			400: '#7551FF',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A'
		},
		brandTabs: {
			100: '#E9E3FF',
			200: '#422AFB',
			300: '#422AFB',
			400: '#422AFB',
			500: '#422AFB',
			600: '#3311DB',
			700: '#02044A',
			800: '#190793',
			900: '#02044A'
		},
		secondaryGray: {
			100: '#E0E5F2',
			200: '#E1E9F8',
			300: '#F4F7FE',
			400: '#E9EDF7',
			500: '#8F9BBA',
			600: '#A3AED0',
			700: '#707EAE',
			800: '#707EAE',
			900: '#1B2559'
		},
		red: {
			100: '#FEEFEE',
			500: '#EE5D50',
			600: '#E31A1A'
		},
		blue: {
			50: '#EFF4FB',
			500: '#3965FF'
		},
		orange: {
			100: '#FFF6DA',
			500: '#FFB547'
		},
		green: {
			100: '#E6FAF5',
			500: '#01B574'
		},
		lightOrange: {
              100: '#FFB795',
              500: '#FFB795'
		},
		mxPeach: {
              100: '#ff9a9e',
              500: '#ff9a9e'
		},
		peach: {
              100: '#ffa489',
              500: '#ffa489'
		},
		mxLightPeach: {
              100: '#FFF5F3',
              500: '#FFF5F3'
		},
		brightOrange: {
			100: "#FFAC71",
			500: "#FFAC71"
		},
		darkOrange: {
              100: '#FF8347',
              500: '#FF8347'
		},
		dullOrange: {
              100: '#f8bea5',
              500: '#f8bea5'
		},
		offOrange :{ 
				100: '#f8dbce',
				500: '#f8dbce'
		},
		lightYellow: {
			100: "#F8D372",
			500: "#F8D372"
		},
		yellow: {
			100: "#F0C427",
			500: "#F0C427"
		},
		lightRed: {
			100: "#FF8C8C",
			500: "#FF8C8C"
		},
		lightGreen: {
              100: "#8EECCF",
              500: "#8EECCF"
		},
		lightBlue: {
            100: '#A3B8E4',
            500: '#A3B8E4'
		},
		kindle: {
            
            500: '#F29360'
		},
		gradientOrange: {
             100: "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)",
			 500: "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)",
		},
		navy: {
			50: '#d0dcfb',
			100: '#aac0fe',
			200: '#a3b9f8',
			300: '#728fea',
			400: '#3652ba',
			500: '#1b3bbb',
			600: '#24388a',
			700: '#1B254B',
			800: '#111c44',
			900: '#0b1437'
		},
		bg: {
			100: '#e9edf2'
		},
		hoverBg: {
			100: "#fafafa",
			200: "#e0e0e0",
			300: "#edf1f6",
		},
		text: {
            100: "#A3AED0",
			200: "#838383"
		},
		exploreOrange: {
			100: "#FFF5F3",
			200: "#ffa480",
		},
		exploreCyan: {
			100: "#ecfcfa" ,
			200: "#2ad1b7"
		},
		exploreBlue: {
			100: "#e7f0ff" ,
			200: "#74a8ff"
		},
		explorePink: {
			100: "#f8f7ff" ,
			200: "#928fc3"
		}
	},
	styles: {
		global: (props) => ({
			body: {
				overflowX: 'hidden',
				bg: mode('#fdfeff', 'navy.900')(props),
				fontFamily: 'DM Sans',
				letterSpacing: '-0.5px'
			},
			input: {
				color: 'gray.700'
			},
			html: {
				fontFamily: 'DM Sans'
			}
		})
	}
};
